import {
  trapFocusOnModal
} from "./trap-focus-on-modal";
import easyToggle from 'easy-toggle-state';

(function () {
  easyToggle()
  const ESC_KEY = 27;
  const body = document.querySelector('body');

  const updateDialog = (dialog) => {
    body.style.overflow = 'hidden';

    if (dialog.classList.contains('is-active')) {
      trapFocusOnModal(dialog);
    }
  }

  const closeDialog = (dialog) => {
    dialog.setAttribute('aria-hidden', 'true');
    dialog.classList.remove('is-active');
    body.style.overflow = 'visible';
  };

  const closeDialogWithEsc = evt => {
    if (evt.keyCode === ESC_KEY) {
      evt.preventDefault();
      const activeDialog = evt.target.closest('.Dialog');

      closeDialog(activeDialog);
    }
  };

  const dialogTriggers = document.querySelectorAll('.jsDialogTrigger');
  const dialogContainer = document.querySelector('.Dialog-container');
  const dialogCloseButtons = document.querySelectorAll('.jsDialogCloseButton');
  const dialogCloseButton = document.querySelector('.jsDialogCloseButton');
  const dialogBackdrop = document.querySelector('.jsDialogBackdrop');

  window.addEventListener('keydown', closeDialogWithEsc);

  if(dialogTriggers.length > 1) {
    dialogTriggers.forEach(trigger => trigger.addEventListener('click', function () {
      const dialog = this.parentNode.querySelector('.Dialog');
      updateDialog(dialog);
    }));
  } else {
    dialogTriggers[0].addEventListener('click', function () {
      const dialog = this.parentNode.querySelector('.Dialog');
      updateDialog(dialog);
    });
  }

  if (dialogCloseButtons.length > 1) {
    dialogCloseButtons.forEach(button => button.addEventListener('click', function () {
      const dialog = this.closest('.Dialog');
      closeDialog(dialog);
    }));
  } else {
    dialogCloseButton.addEventListener('click', function () {
      const dialog = this.closest('.Dialog');
      closeDialog(dialog);
    });
  }

  if(dialogBackdrop) {
    dialogBackdrop.addEventListener('click', function () {
      const dialog = this.closest('.Dialog');
      closeDialog(dialog)
    });
  }
})()
